import React from 'react'
import {useHistory} from 'react-router-dom'

import {
    makeStyles,
    Container,
    Card,
    Typography,
    CardActions,
    Button
} from '@material-ui/core'

const useStyle = makeStyles(theme => ({
    root: {
        margin: theme.spacing(2)
    },
    paper: {
        padding: theme.spacing(1,2)
    }
}))

export default (props) => {

    const classes = useStyle()
    const history = useHistory()

    return (
        <div className={classes.root}>
            <Container>
                <Card variant="outlined" className={classes.paper}>
                    <Typography variant="h5" >
                        {props.name}
                    </Typography>

                    <Typography color="textSecondary" >
                        {props.about}
                    </Typography>

                    <CardActions>
                        <Button onClick={()=>{history.push('/clubs/'+props.path)}}>Check us out</Button>
                    </CardActions>

                </Card>
            </Container>
        </div>
    )
}
