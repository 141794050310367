import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#e84a5f',
        },
        secondary: {
            main: '#feceab'
        }
    },
    typography: {
        fontFamily: ['Chelsea Market', 'sans-serif'].join(','),
    },

})

export default theme