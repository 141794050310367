import React from 'react'
import {useParams} from 'react-router-dom'
import Clubs from '../data/clubs'

import {
    Container,
    Typography,
    makeStyles
} from '@material-ui/core'

const useStyle = makeStyles(theme => ({
    root: {
        margin: theme.spacing(3,1)
    },
    title: {
        fontFamily: 'Chelsea Market'
    },
    body: {
        margin: theme.spacing(2)
    }
}))

const Club = () => {
    const classes = useStyle()
    let {name} = useParams()
    let clubData = Clubs.filter((club) => club.path === name )
    return (
        <div className={classes.root}>
            <Container>

                <Typography  variant="h3" className={classes.title}> 
                    {clubData[0].name}
                </Typography>
                <Typography className={classes.body}>
                    {clubData[0].about}
                </Typography>
            
            </Container>
        </div>
    )
}

export default Club