import React from 'react'

import {
    makeStyles,
    Container,
    Grid,
    Typography,
    Divider
} from '@material-ui/core'

// static images 
import culogo from '../images/culogo.png'

// animation 
import { useSpring, animated } from 'react-spring'

// style 
const useStyle = makeStyles(theme => ({
    root: {
        margin: theme.spacing(3, 1)
    },
    image: {
        [theme.breakpoints.down('xs')]: {
            width: '300px'
        }
    }
}))


const Index = () => {

    const classes = useStyle()

    const logoAnimation = useSpring({
        opacity: 1,
        from: {
            opacity: 0,
        },
        config: {
            duration: 2000
        }
    })

    const welcomeMessage = useSpring({
        opacity: 1,
        from: {
            opacity: 0
        },
        config: {
            delay: 2000,
            duration: 2500
        }
    })

    const instruction = useSpring({
        opacity: 1,
        from: {
            opacity: 0
        },
        config: {
            delay: 2000,
            duration: 4500
        }
    })

    return (
        <div className={classes.root}>

            <animated.div style={logoAnimation}>

                <Container>
                    <Grid container spacing={2}>

                        <Grid item xs={12} sm={6}>

                            <img src={culogo} alt="" className={classes.image} />

                        </Grid>
                        <Grid item xs={12} sm={6}>

                            <Typography>
                                Welcome, <br />
                                Check out the awesome clubs that our college has in store.
                        </Typography>

                        </Grid>

                    </Grid>

                    <Divider variant="middle"/>

                    <Grid container>

                        <Grid item xs={12}>
                            <Typography variant="h5" >
                                This website is just a idea.
                            </Typography>

                            <Typography variant="body2">
                                Currently I dont have enough images or data to show in the main page. <br />
                                I plan on showing pictues of different events, and other people's quote so that users feel intrested. 
                            </Typography>

                            <Typography variant="h6">
                                Main Idea
                            </Typography>
                            <Typography variant="body2">
                                The main idea of creating this web app is to bring clubs and students closer. <br/>
                                The Features that I have planned are : 
                                <ul>
                                    <li>Individual pages for every club.</li>
                                    <li>A event Page where all the upcoming events will be shown, also only admins can create new events, by passing in thier unique <strong>Pass Key</strong>  </li>
                                    <ul>
                                        <li>Can write event details in markdown and also preview it before posting it.</li>
                                        <li>Also using <strong>Socket.io</strong> creating a new event will generate a new notifiation.  <strike>Havent implemented this yet</strike>  </li>
                                    </ul>
                                    <li>Also all the data is a dummy, not yet connected to the backend. </li>
                                    <li>This is created using react feel free to check the source code <a target="blank" href="https://github.com/Ninja-Developers/CuClubs">here</a> </li>
                                </ul>
                            </Typography>
                        </Grid>
                    </Grid>



                </Container>

            </animated.div>

        </div>
    )
}


export default Index