import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
    makeStyles,
    Fab,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Tooltip
} from '@material-ui/core'

import {
    Add
} from '@material-ui/icons'

const useStyle = makeStyles(theme => ({
    root: {
        margin: '0px',
        top: 'auto',
        right: '20px',
        bottom: '20px',
        left: 'auto',
        position: 'fixed'
    }
}))

const FloatingButton = () => {
    const classes = useStyle()
    const [dialog, toggleDialog] = useState(false)
    const history = useHistory()

    return (
        <div className={classes.root}>
            <Tooltip title="Add event">
                <Fab
                    color="primary"
                    onClick={() => { toggleDialog(true) }}
                >
                    <Add />
                </Fab>
            </Tooltip>

            <Dialog
                open={dialog}
                onClose={() => { toggleDialog(false) }}
            >
                <DialogTitle>
                    Only For Admins
            </DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        Only proceed if you are a Admin.
                </DialogContentText>

                    <DialogActions>
                        <Button
                            onClick={() => { history.push('/addevent') }}
                        >proceed</Button>

                        <Button
                            onClick={() => { toggleDialog(false) }}
                        >Cancel</Button>
                    </DialogActions>
                </DialogContent>

            </Dialog>
        </div>
    )
}

export default FloatingButton