export default  [
    {
        name: 'HERITAGE CLUB',
        about: `A concerted effort to preserve our heritage is a vital link to our cultural,
        educational, aesthetic, inspirational and economic legacies – all of the things
        that quite literally make us who are here at heritage club, we conduct workshops
        and events to remind us that "A nation’s culture resides in the hearts and
        in the soul of its people".`,
        path: 'heritage'
    },
    {
        name: 'TECHABODE CLUB',
        about: `Computer networks help users on the network to share the resources and in
        communication. We can’t imagine a world now without emails,
        online newspapers, blogs, chat and the other services offered by the internet. Here
        At Techabode club, we deliver workshops on concepts of computer networking
        making students ready for greatest of Web.`,
        path: "techabode"
    },
    {
        name: 'PRESENTERS CLUB',
        about: `Effective presentation skills are important because they help the speaker communicate with
        confidence, and motivate the audience to listen and share ideas effectively. Some
        essential presentation skills are: Creating variety. Speak up with optimal audibility.
        Here at Presenters club, we nourish and endorse the presentation and communication
        Skills making them amiable and confident.`,
        path: 'presenters'
    },
    {
        name: 'C-SQUARE CLUB',
        about: `Train your mind to think faster and to think in new ways that no
        other programmer is capable of. Here at C-Square club, we
        teach many competitive programming practices and techniques
        that help the students to stand out being the fastest.`,
        path: 'csquare'
    },
    {
        name: 'PIONEER CLUB',
        about: `Our world revolves around Data and Information. To
        make this information more useful, easy to access
        and protected we use database management systems.
        Here at Pioneer club, we introduce students with
        various types and concepts of databases and make
        them better at managing them.`,
        path: 'pioneer'
    },
    {
        name: 'INVENTO CLUB',
        about: `Emerging technologies are technical innovations that
        represent progressive developments within a field
        for competitive advantage. Here at Invento club, we host
        varous workshops and Expert talks on the emerging
        technologies and inventions in fields of computer
        science and give them a little hands on so students can get
        started with it.`,
        path: 'invento'
    },
    {
        name: 'ANDROIDHIVE CLUB',
        about: `Android is one of the leading mobile Operating system in the market. We
        all use android phones which provides huge variety of applications and
        entertainment for us. Android Hive can be summed up not just where we
        do android but also where we teach and implement best software
        practices, design architecture and programming. Here, rather than being
        just a problem seeker, we will be solving those.`,
        path: 'androidhive'
    },
    {
        name: 'WebSparks CLUB',
        about: `A website is your most powerful tool for communication. A
        website is a unique way to connect with the world. Whether you
        choose to create a website to share your passion for music,
        inform people of your business, sell products, or any other
        reason—there are no boundaries to what you can do!
        Here at WebSparks, we train students in developing and
        designing websites and help them build their projects.`,
        path: 'websparks'
    }
]