export default [
    {
        title: 'Code Like a pro',
        body: `Learn To **code** like a pro with us, join our 
        seminar.`,
        club: 'androidhive'
    },
    {
        title: 'Code Like a pro',
        body: `Learn To **code** like a pro with us, join our 
        seminar.`,
        club: 'androidhive'
    },
    {
        title: 'Code Like a pro',
        body: `Learn To **code** like a pro with us, join our 
        seminar.`,
        club: 'androidhive'
    }
]