import React, { useState } from 'react'
import {
    useHistory,
    useLocation
} from 'react-router-dom'

// material ui components 
import {
    makeStyles,
    AppBar,
    Toolbar,
    Typography,
    Button,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Badge,
    Tooltip
} from '@material-ui/core'
import {
    Notifications
} from '@material-ui/icons'


// Styles 
const useStyle = makeStyles(theme => ({
    root: {
        flexGrow: 1
    },
    title: {
        flexGrow: 1,
        cursor: 'pointer'
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}))

const Navbar = () => {

    const classes = useStyle()
    let history = useHistory()
    let location = useLocation()
    const [Notification, ToggleNotification] = useState(false)

    console.log(location)

    const handleNotificationOpen = () => {
        ToggleNotification(true)
    }

    const handleNotificationClose = () => {
        ToggleNotification(false)
    }

    return (
        <div className={classes.root}>
            <AppBar variant="outlined" position="static">
                <Toolbar>
                    <Typography
                        variant="h6"
                        className={classes.title}
                        onClick={() => { history.push('/') }}
                    >
                        CU Clubs
                    </Typography>

                    {/* {(location.pathname === ('/clubs') ? <InputBase
                        placeholder="Search…"
                        classes={{
                            root: classes.inputInput
                        }}
                    /> : null)} */}

                    <Tooltip title="Notification">
                        <IconButton
                            color="inherit"
                            onClick={handleNotificationOpen}
                        >
                            <Badge badgeContent={4} color="secondary">
                                <Notifications />
                            </Badge>
                        </IconButton>
                    </Tooltip>

                    <Button
                        color="inherit"
                        onClick={() => { history.push('/clubs') }}
                    >
                        Clubs</Button>
                    <Button
                        color="inherit"
                        onClick={() => { history.push('/events') }}
                    >Events</Button>
                </Toolbar>
            </AppBar>
            <Dialog
                open={Notification}
                onClose={handleNotificationClose}
            >
                <DialogTitle>
                    Notification
            </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Let Google help apps determine location. This means sending anonymous location data to
                        Google, even when no apps are running.
          </DialogContentText>
                </DialogContent>

            </Dialog>
        </div>
    )
}

export default Navbar