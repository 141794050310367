import React from 'react'
import Markdown from 'react-markdown'

import {
    makeStyles,
    Container,
    Card,
    Typography,
    CardActions,
    Button
} from '@material-ui/core'

const useStyle = makeStyles(theme => ({
    root: {
        margin: theme.spacing(3,1)
    },
    paper: {
        padding: theme.spacing(1,2)
    }
}))

export default (props) => {

    const classes = useStyle()

    return (
        <div className={classes.root}>
            <Container>
                <Card variant="outlined" className={classes.paper}>

                    <Typography variant="h5">
                        {props.title}
                    </Typography>

                    <Typography>
                        <Markdown source={props.body} />
                    </Typography>

                </Card>

            </Container>
        </div>
    )
}