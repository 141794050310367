import React from 'react'
import clubs from '../data/clubs'
import Holder from '../components/Clubs/holder'

import {
    Container
} from '@material-ui/core'



const Clubs = () => {
    return (
        <div>
            <Container>
                {clubs.map(club => <Holder name={club.name} about={club.about} path={club.path} />)}
            </Container>
        </div>
    )
}

export default Clubs