import React from 'react'
import events from '../data/events'

import {
    Container
} from '@material-ui/core'

// custom components 
import Floating from '../components/buttons/eventcreatbtn'
import Holder from '../components/event/holder'

const Event = () => {

    return (
        <div>
            <Container>
                {events.map(event => <Holder title={event.title} body={event.body} />)}

            </Container>


            <Floating />
        </div>
    )
}

export default Event