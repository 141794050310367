import React from 'react';
import {Route, Switch} from 'react-router-dom'

// import pages 
import Index from './pages/index'
import Clubs from './pages/clubs'
import Event from './pages/events'
import Club from './pages/club'
import AddEvent from './pages/addEvents'

// import Components
import Navbar from './components/navbar/index'

function App() {
  return (
    <div>
      <Navbar/>

      <Switch>
        <Route exact path="/" component={Index}/>
      </Switch>

      <Switch>
        <Route exact path="/clubs" component={Clubs} />
      </Switch>

      <Switch>
        <Route exact path="/events" component={Event} />
      </Switch>

      <Switch>
        <Route path="/clubs/:name" component={Club}/>
      </Switch>

      <Switch>
        <Route exact path="/addevent" component={AddEvent}/>
      </Switch>

    </div>
  );
}

export default App;
