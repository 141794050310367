import React, { useState } from 'react'
import Clubs from '../data/clubs'
import ReactMarkdown from 'react-markdown'

import {
    makeStyles,
    Container,
    Typography,
    FormControl,
    Divider,
    TextField,
    Button,
    Select,
    MenuItem,
    Paper,
    Grid,
    FormHelperText,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText
} from '@material-ui/core'

const useStyle = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2)
    },
    form: {
        padding: theme.spacing(4, 3)
    },
    title: {
        margin: theme.spacing(3)
    },
    body: {
        margin: theme.spacing(3)
    },
    helper: {
        margin: theme.spacing(0, 3)
    },
    preview: {
        margin: theme.spacing(3),
        color: '#99b898'
    }
}))

const AddEvent = (props) => {

    const classes = useStyle()

    const [preview, togglePreview] = useState(false)
    const [title, titleHandler] = useState('')
    const [body, bodyHandler] = useState('')

    return (
        <div>
            <Container>
                <div className={classes.root}>
                    <Typography align="center" variant="h4">
                        Add a Event
                </Typography>
                    <Divider variant="middle" />
                </div>



                <Paper variant="outlined">
                    <Container>
                        <Grid container>
                            <Grid item xs={12}>
                                <FormControl fullWidth >
                                    <TextField
                                        className={classes.title}
                                        variant="outlined"
                                        label="Title"
                                        value={title}
                                        onChange={(event) => { titleHandler(event.target.value) }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container>

                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth>
                                            <Select variant="outlined" className={classes.title}>
                                                {Clubs.map(club => <MenuItem value={club.path} >{club.name}</MenuItem>)}
                                            </Select>
                                            <FormHelperText className={classes.helper}>Select a Club</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth >
                                            <TextField
                                                className={classes.title}
                                                variant="outlined"
                                                label="Admin Key"
                                                type="password"
                                                
                                            />
                                        </FormControl>
                                    </Grid>

                                </Grid>

                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth >
                                    <TextField 
                                    className={classes.body} 
                                    variant="outlined" 
                                    label="Body" 
                                    multiline 
                                    value={body}
                                    onChange={(event) => { bodyHandler(event.target.value) }}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth >
                                    <Button
                                        className={classes.body}
                                        color="primary"
                                        variant="contained"
                                        onClick={() => { togglePreview(true) }}
                                    >Preview</Button>
                                </FormControl>

                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth >
                                    <Button 
                                    className={classes.body} 
                                    color="primary" 
                                    variant="outlined"
                                    >Add</Button>
                                </FormControl>

                            </Grid>

                        </Grid>
                    </Container>
                </Paper>


            </Container>

            <Dialog
                open={preview}
                onClose={() => { togglePreview(false) }}
            >

                <DialogTitle>
                    {title}
                </DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        <ReactMarkdown source={body}/>
                    </DialogContentText>

                </DialogContent>



            </Dialog>

        </div>
    )
}

export default AddEvent